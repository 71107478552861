body {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 300;
    overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Fira Sans', sans-serif;
    text-rendering: optimizeLegibility;
}

.page-content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    .wrapper {
        flex: 1;
    }
}

.home {
    overflow: hidden;
}

.logo {
    display: block;
    margin: 90px auto 80px;
    width: 400px;
    height: 400px;
    border: 11.3262379px solid #272727;
    transform: rotate(11.3262379deg);
}

.footer {
    p {
        color: #272727;
        line-height: 1;
        font-family: 'Fira Sans', sans-serif;
        font-size: 0.9rem;
        font-weight: 300;
        text-transform: uppercase;
        text-align: center;
    }
}
