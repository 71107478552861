.content {
    padding: 40px 0;

    h2 {
        font-size: 1.2rem;
    }

    .corpus {
        padding: 20px 0 50px;

        p {
            color: #272727;
            font-family: 'Raleway', sans-serif;
            font-weight: 300;
            font-size: 1rem;
            text-rendering: optimizeLegibility;

            b {
                font-weight: 700;
            }
        }
    }
}
